import * as React from 'react';
import { useMemo, useEffect } from 'react';
import { ComboBox } from '@fluentui/react';
export var Dropdown = function (_a) {
    var items = _a.items, value = _a.value, onChange = _a.onChange, label = _a.label, placeholder = _a.placeholder;
    var defaultValue = useMemo(function () { var _a; return (_a = items === null || items === void 0 ? void 0 : items.find(function (item) { return item.default; })) === null || _a === void 0 ? void 0 : _a.id; }, [items]);
    useEffect(function () {
        if (!value && defaultValue)
            onChange(defaultValue);
    }, [value, defaultValue, onChange]);
    var options = useMemo(function () { return items === null || items === void 0 ? void 0 : items.map(function (item) { return ({
        key: item.id,
        text: item.name,
        data: item
    }); }); }, [items]);
    return (React.createElement(ComboBox, { autoComplete: "on", placeholder: placeholder, label: label, selectedKey: value, onChange: function (ev, option) { return onChange(option.key); }, onRenderOption: function (option) { return React.createElement(React.Fragment, null, option.data.name); }, options: options }));
};
